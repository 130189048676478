<template>
	<div id="app" style="overflow-x: hidden;">
		<Header />

		<div class="container is-fluid" style="padding: 0">
			<div class="columns mt-0 mb-0 pt-0 pb-0">
				<div class="column is-12 is-offset- mt-0 mb-0 pt-0 pb-0">
					<router-view />
				</div>
			</div>
		</div>

		<div v-if="$route.name !== 'Checkout'" ref="whatsapp" id="whatsapp" @click="openWhatsappApi"></div>

		<Footer />
	</div>
</template>

<style lang="sass">
@import './assets/sass/app.scss'
</style>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
	components: {
		Header,
		Footer,
	},
	watch: {
		$route() {
			this.scrollToTop();
		},
	},
	methods: {
		scrollToTop() {
			window.scrollTo(0, 0);
		},
    openWhatsappApi() {
      window.open('https://87.orderlemon.com/', '_blank');
    },

  },
	mounted() {
		let self = this;

		window.addEventListener("scroll", (e) => {
			let scrollToBottom =
				e.srcElement.body.clientHeight -
				(e.srcElement.scrollingElement.scrollTop + window.innerHeight);

			let classList =
				typeof self.$refs.whatsapp !== "undefined" &&
				typeof self.$refs.whatsapp.classList !== "undefined"
					? self.$refs.whatsapp.classList
					: false;

			if (classList && scrollToBottom < 110) {
				self.$refs.whatsapp.classList;
				if (!classList.contains("is-active")) {
					classList.add("is-active");
				}
			} else if(classList !== false) {
				if (classList.contains("is-active")) {
					classList.remove("is-active");
				}
			}
		});
	},
};
</script>

<style lang="scss">

@media only screen and (max-width: 768px) {
	#whatsapp {
		display: none;
	}
}

@keyframes attention {
  0%,90%{
    transform: scale(1.00);
  }
  91%{
    transform: scale(1.00) rotateZ(3deg);
  }
  95%{
    transform: scale(1.05) rotateZ(-3deg);
  }
  99%{
    transform: scale(1.00) rotateZ(3deg);
  }
}

#whatsapp {
  cursor: pointer;
	position: fixed;
	right: 2em;
	bottom: 2em;
	height: 63px;
	width: 250px;
	background-image: url("assets/images/app_ons.png");
	background-size: cover;
	border-radius: 7px;
	z-index: 999;
  box-shadow: 0 0 18px 0 #383838;
  animation: attention 8s infinite;

	&.is-active {
		position: absolute;
		bottom: calc(106px + 2em);
	}
}
</style>

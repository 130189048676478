<template>
  <footer class="footer" :class="c">
    <div class="content">
      <div class="columns">
        <div class="column is-4">
          <p class="left">
            <a
              href="https://www.facebook.com/pages/category/Bakery/Bakkerij-t-Broodhuys-219154808146913/"
              target="_blank"
            >
              <font-awesome-icon :icon="['fab', 'facebook']" size="lg" />
              <span>Bakkerij 't Broodhuys</span>
            </a>

            <a
              href="https://www.instagram.com/bakkerijtbroodhuys/"
              target="_blank"
            >
              <font-awesome-icon :icon="['fab', 'instagram']" size="lg" />
              <span>@bakkerijtbroodhuys</span>
            </a>
          </p>
        </div>

        <div class="column is-4">
          <p class="middle">
            © {{ new Date().getFullYear() }} Bakkerij 't Broodhuys
            <small class="mt-2 is-block">
              <a href="https://privacy.mykit.ws/policy/bakkerijbroodhuys.nl">Privacybeleid</a>
            </small>
          </p>
        </div>

        <div class="column is-4">
          <p class="right">
            <a href="mailto:info@bakkerijbroodhuys.nl">
              <font-awesome-icon :icon="['fas', 'envelope']" size="lg" />
              <span>info@bakkerijbroodhuys.nl</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@keyframes link-hover {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  49% {
    transform: scaleX(1);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: right;
  }
  100% {
    transform-origin: right;
    transform: scaleX(0);
  }
}

.footer {
  padding: 2.5rem 1.5rem;
  font-weight: 600;
  display: inline-block;
  width: 100vw;

  .columns .column {
    text-align: center;
    position: relative;

    > p {
      display: contents;
    }

    a {
      display: block;
      color: black;
      margin: 0 0 10px;
      &:last-child {
        margin-bottom: 0;
      }

      span {
        position: relative;
        overflow: hidden;
        margin-left: 8px;
        &:after {
          content: "";
          position: absolute;
          width: 104%;
          height: 2px;
          background: black;
          left: -2%;
          bottom: 0.5em;
          transform: scaleX(0);
        }
      }

      &:hover {
        span {
          &:after {
            animation: link-hover 700ms ease;
          }
        }
      }
    }

    .left {
      text-align: left;
    }
    .middle {
      display: inline-block;
      position: absolute;
      width: 100%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .right {
      text-align: right;
    }
  }

  &.black {
    background: transparent;
    color: white;
    .columns .column {
      a {
        color: white !important;

        span {
          &:after {
            background: white !important;
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 768px) {
  .footer .columns .column {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    p {
      text-align: center !important;
    }
  }
}
</style>

<script>
export default {
  name: "Footer",
  computed: {
    c() {
      return [
          'OnsVerhaal',
          'Contact',
      ].indexOf(this.$route.name) > -1 ? [ 'black' ] : [ ];
    }
  }
};
</script>

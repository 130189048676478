import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from 'axios'
import VueAxios from 'vue-axios';

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy, {
  defaultIconPack: 'fas'
});


// @ts-ignore
import PouchDB from 'pouchdb-browser';
// @ts-ignore
import PouchFind from "pouchdb-find";
// @ts-ignore
import PouchLiveFind from 'pouchdb-live-find';

PouchDB.plugin(PouchFind);
PouchDB.plugin(PouchLiveFind);

import PouchVue from 'pouch-vue';

Vue.use(PouchVue, {
  pouch: PouchDB,
  defaultDB: 'bakkerijbroodhuys_v000001',
});

// @ts-ignore
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

Vue.use(VueAxios, axios);

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCoffee, faEnvelope, faBars, faShoppingCart, faAngleDown, faAngleUp, faCreditCard, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faFacebook, faWhatsapp, faIdeal } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faCoffee, faEnvelope, faInstagram, faFacebook, faWhatsapp, faBars, faShoppingCart, faAngleDown, faAngleUp, faIdeal, faCreditCard, faExclamationTriangle);

Vue.component("font-awesome-icon", FontAwesomeIcon);


Vue.config.productionTip = false;

import { translate } from "@/mixins/translator/translate";
import { domainKit } from "@/mixins/iosystems/DomainKit";
import { shopKit } from "@/mixins/iosystems/ShopKit";

new Vue({
  data: {
    stores: [
        {
          name: "Enkhuizen",
          address: "Westerstraat 76\n1601 AL\nEnkhuizen",
          text: "Westerstraat 76, 1601 AL, Enkhuizen",
          phone: "+31228313713",
          coordinates: [5.29068, 52.70415]

        },
        {
          name: "Hoogkarspel",
          address: "Nieuweweg 15\n1616 BA\nHoogkarspel",
          text: "Nieuweweg 15, 1616 BA, Hoogkarspel",
          phone: "+31228561951",
          coordinates: [5.17731, 52.69340]
        },
        {
          name: "Hem",
          address: "Hemmerbuurt 115\n1607 CE\nHem",
          text: "Hemmerbuurt 115, 1607 CE, Hem",
          phone: "+31228544038",
          coordinates: [5.18282, 52.66050]
        },
        {
          name: "Winkelcentrum Streekhof",
          address: "Middend 28A\n1611 KW\nBovenkarspel",
          text: "Middend 28A, 1611 KW, Bovenkarspel",
          phone: "+31228785167",
          coordinates: [5.234647, 52.6965922]
        },
    ],
  },
  router,
  mixins: [
    domainKit,
    shopKit,
    translate
  ],
  render: function(h) {
    return h(App);
  },
}).$mount("#app");

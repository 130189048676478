let environment = 'local';
if(process.env.NODE_ENV === 'production') {
    environment = process.env.NODE_ENV;
}

export const domainKit = {

    data() {
        return {
            /**
             * TODO: change to environment stuff
             */

            domain: {
                local: {
                    token: 'NTdhNmFhMmVlM2M4ZTJlOWQ5MzFlZDQ5YWY1MWJhNzA5ZWI4ZGM4MDVmY2U3NGJmY2E4OWM=',

                    shop: {
                        endpoint: "http://shop.kit/api",
                    },
                    stripe: {
                        key: 'pk_test_51HmRRFHZDV2ZxwKJINlzTGlKJalRz3xDSu3oJKXMWm1WHStBt9OkHWvW4Q9IGLq7fA9CsAuALvVlKba0LY5qlF3u00M7RTh376',
                    },
                    language: 'nl-NL',
                },
                production: {
                    token: 'NTdhNmFhMmVlM2M4ZTJlOWQ5MzFlZDQ5YWY1MWJhNzA5ZWI4ZGM4MDVmZTBjYTk0OTVjMzc=',

                    shop: {
                        endpoint: "https://shop.mykit.ws/api",
                    },
                    stripe: {
                        key: 'pk_live_51HmRRFHZDV2ZxwKJ4cXWNCsu4QXWkXMD6DHi3LiuD5tIrooQGhPzs0tLA3GrVev56H01NMElWQp1WNb5sQxj2zUZ00RA68HMPP',
                    },
                    language: 'nl-NL',
                }
            }[environment],
        };
    },

    created() {

        const self = this;

        self.axios.defaults.headers.common = {
            'X-APP-KIT-TOKEN': self.domain.token,
        };

    },

};
export const cart = {

    data() {
        return {
            cart: {
                selections: {},
                shipping: {
                    costs: 3.00
                }
            },
        };
    },

    computed: {

        totalAmount() {
            const self = this;
            let amount = 0;
            for(let i in self.cart.selections) {
                if(typeof self.cart.selections[i] !== 'undefined' &&
                   typeof self.cart.selections[i].selectedPrice !== 'undefined' &&
                   typeof self.cart.selections[i].selectedPrice.price !== 'undefined'
                ) { amount += self.cart.selections[i].selectedPrice.price.unit_amount * self.cart.selections[i].quantity; }
                else if (
                    typeof self.cart.selections[i].prices !== 'undefined' &&
                    typeof self.cart.selections[i].prices[0] !== 'undefined'
                ) { amount += self.cart.selections[i].prices[0].unit_amount * self.cart.selections[i].quantity; }
            }

            return amount / 100;
        },

        totalAmountWithDiscount() {
            const self = this;
            let amount = (self.totalAmount * 100);
            if(self.hasDiscount > 0) {
                amount -= (amount * (self.hasDiscount / 100));
            }
            return (Math.floor(amount) / 100);
        },

        hasDiscount() {
            this.cart.selections;
            return (new Date() <= new Date(Date.parse('2021-03-31T23:59:59'))) ? 10 : 0;
        }


    },

    created() {
        const self = this;
        self.$pouch.get('cart.selections').then(function(document) {
            self.cart.selections = document.selections;
        });
    },

    methods: {
        addToCart(product) {
            const self = this;
            self.$set(self.cart.selections, product.id, Object.assign({
                quantity: 1,
                options: {}, // TODO:
            }, product));
            self.syncCartSelections();
        },
        removeFromCart(product) {
            const self = this;
            self.$delete(self.cart.selections, product.id);
            self.syncCartSelections();
        },
        syncCartSelections() {
            const self = this;
            self.$pouch.get('cart.selections').then(function(document) {
                self.$pouch.put(Object.assign(document, {
                    _id: 'cart.selections',
                    selections: self.cart.selections,
                }));
            }).catch(function() {
                self.$pouch.put(Object.assign({
                    _id: 'cart.selections',
                    selections: self.cart.selections,
                }));
            })

        },
        clearCart() {
            const self = this;
            self.$pouch.get('cart.selections').then(function(document) {
                return self.$pouch.remove(document);
            }).then(function() {
                self.cart.selections = {};
            }).catch(function() { });
        },

        cleanEverything() {
            const self = this;
            self.clearCart();
            self.$pouch.get('products').then(function(document) {
                return self.$pouch.remove(document);
            }).then(function() {

            }).catch(function() { });
        }
    }

};
export const order = {

    data() {
        return {
            order: {
                lastOrder: null,
                loaders: {
                    payment: false,
                    putting: false,
                    retrieving: false,
                    opflow: false,
                },
            },
        }
    },

    methods: {

        handleOrderDeliveryPaymentFlow(payload = {}, success = function() {}, failure = function() {}) {

            const self = this;

            if(!self.order.loaders.opflow) {

                self.order.loaders.opflow = true;

                self.axios.post(`${self.$root.domain.shop.endpoint}/stripe/payment/order/delivery/intent`, payload)
                    .then(success)
                    .catch(failure)
                    .then(function() { self.order.loaders.opflow = false });


            }

        },

        handleOrderPickupPaymentFlow(payload = {}, success = function() {}, failure = function() {}) {

            const self = this;

            if(!self.order.loaders.opflow) {

                self.order.loaders.opflow = true;

                self.axios.post(`${self.$root.domain.shop.endpoint}/stripe/payment/order/pickup/intent`, payload)
                    .then(success)
                    .catch(failure)
                    .then(function() { self.order.loaders.opflow = false });


            }

        },

        paymentIntent(payload = {}, success = function() {}, failure = function() {}) {

            const self = this;

            if(!self.order.loaders.payment) {

                self.order.loaders.payment = true;

                self.axios.post(`${self.$root.domain.shop.endpoint}/stripe/payment/intent`, payload)
                    .then(success)
                    .catch(failure)
                    .then(function() { self.order.loaders.payment = false });


            }

        },

        putOrder(payload = {}, success = function() {}, failure = function() {}) {

            const self = this;

            if(!self.order.loaders.putting) {

                self.order.loaders.putting = true;

                self.axios.put(`${self.$root.domain.shop.endpoint}/stripe/order`, payload)
                          .then(success)
                          .catch(failure)
                          .then(function() { self.order.loaders.putting = false });


            }


        }

    }

};
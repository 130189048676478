<template>

    <nav class="navbar is-fixed-top is-black px-4">
      <div class="navbar-brand">
        <router-link class="navbar-item px-3" to="/">
          <img src="../assets/images/broodhuys_logo.png" alt="Bulma: a modern CSS framework based on Flexbox" width="112" height="28">
        </router-link>
        <div class="navbar-burger burger" @click="toggleMenu" data-target="navbarExampleTransparentExample">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div :class="['has-background-black' ,'navbar-menu', { 'is-active' : menuOpened }]">
        <div class="navbar-start">

          <router-link
              to="/"
              class="navbar-item"
              :class="{ 'is-active': $route.name === 'Home' }"
          >
            <p  @click="menuOpened = false;" class="item-content">{{ $root.t("Home") }}</p>
          </router-link>

          <router-link
              to="/het-begin"
              class="navbar-item"
              :class="{ 'is-active': $route.name === 'HetBegin' }"
          >
            <p  @click="menuOpened = false;" class="item-content">{{ $root.t("Het begin") }}</p>
          </router-link>

          <router-link
              to="/over-ons"
              class="navbar-item"
              :class="{ 'is-active': $route.name === 'Contact' }"
          >
            <p  @click="menuOpened = false;" class="item-content">{{ $root.t("Over ons") }}</p>
          </router-link>

        </div>

      </div>
    </nav>

</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      menuOpened: false,
    }
  },
  methods: {
    toggleMenu(){
      let self = this;
      self.menuOpened = !self.menuOpened;
    },
  }
};
</script>

<style lang="scss" scoped>

@import "node_modules/bulma/sass/utilities/_all.sass";

nav.navbar {
  z-index: 9999;

  .navbar-item {
    color: #727272;
    background-color: transparent;

    &:hover, &:active {
      background-color: transparent;
    }

    p{
      position: relative;
      overflow: visible;
      width: 125px;
      text-align: center;
      color: #727272;
      transition: all 150ms;
    }

    &:hover, &.is-active {
      background-color: transparent !important;

      p{
        color: #e0e0e0;
        font-weight: bolder;
      }
    }

  }
}

</style>
